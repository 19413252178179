<template>
  <div class="view-post-page">
    <v-card v-if="post && post.is_draft" color="yellow lighten-4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            This {{ post.is_alert? `message` : `notice` }} is scheduled for <strong class="orange--text">{{ moment(post.scheduled).format('LLL') }}</strong>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon v-if="canManage"
          ><v-btn @click="showPublishDialog = true" small color="primary"
            ><v-icon class="mr-2">mdi-publish</v-icon> <span v-if="post.is_alert">Send</span> <span v-else>Post</span> Now</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>
    <v-row no-gutters class="my-2" justify="center">
      <v-col md="6">
        <div v-if="isLoading">
          <v-skeleton-loader
            type="list-item-avatar, list-item-three-line"
          ></v-skeleton-loader>
        </div>
        <div class="post-content" v-if="post && !isLoading">
          <v-list-item>
            <v-list-item-avatar>
              <v-img
                :src="post.is_alert ? post.user.image : currentInstitution.logo"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="subtitle-2">
              {{
                post.is_alert ? post.user.full_name : currentInstitution.name
              }}
            </v-list-item-content>
          </v-list-item>
          <div class="mx-5" v-if="post">
            <div class="content-description my-0" 
              v-html="TextHelper.linkify(post.description)"
            >
            </div>

            <div v-if="post.rooms.length > 1" class="body-2 mt-4">
              Broadcasted to: 
              <p>
                <v-chip 
                  small
                  color="primary"
                  class="my-1 mr-1"
                  v-for="room in post.rooms"
                  :key="room.id"
                  outlined
                >
                  {{ allRooms.find(r => r.id == room) ? allRooms.find(r => r.id == room).sections : "" }}  
                </v-chip>
              </p>
            </div>
            <v-row class="mt-2 mb-2" dense>
              <v-col
                cols="4"
                md="3"
                v-for="image in post.board_images"
                :key="image.id"
              >
                <v-img
                  class="cursor-pointer"
                  @click="showFullScreenCarousel(image)"
                  :src="image.image"
                  aspect-ratio="1"
                >
                </v-img>
              </v-col>
            </v-row>

            <full-screen-image-carousel
              v-if="post"
              :initial-image="initialImage"
              :images="post.board_images"
              :visible="fullScreenImageOverlay"
              @closeDialog="fullScreenImageOverlay = false"
            />
            <v-divider v-if="post && post.board_documents.length" />
            <v-list
              class="my-0 py-0"
              v-if="post && post.board_documents.length"
            >
              <v-list-item
                class="px-0"
                color="red"
                v-for="boardDocument in post.board_documents"
                :key="boardDocument.id"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="py-0 text-wrap">
                  <v-list-item-subtitle class="font-weight-bold" v-if="boardDocument.file_name.split('.').length > 1">
                    {{boardDocument.file_name.split('.').slice(-1)[0].toUpperCase() }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-2 text-wrap">
                  {{ boardDocument.file_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <v-btn
                    outlined
                    color="primary"
                    class="non-focused"
                    v-if="boardDocument.progress == 100"
                    @click="openFile(boardDocument)"
                    ><v-icon>mdi-check</v-icon> Open</v-btn
                  >
                  <v-btn
                    @click="downloadDocument(boardDocument)"
                    color="primary"
                    class="non-focused"
                    icon
                    v-else
                  >
                    <v-icon v-if="boardDocument.progress == null"
                      >mdi-download</v-icon
                    >
                    <span v-else>{{ boardDocument.progress }}%</span>
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

            <p class="subtitle grey--text mb-2">
              - {{ post.user.full_name }}
            </p>

            <p class="caption grey--text">
              {{ moment(post.created).format("hh:mmA, Do MMM") }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- Replies -->
    <v-row justify="center">
      <v-col md="6">
        <board-post-replies
          class="mx-4"
          v-if="post && post.allow_replies"
          :post-id="post.id"
          ref="boardPostReplies"
        />
      </v-col>
    </v-row>
    <!-- <create-board-post-sheet
      class="edit-board-post-sheet"
      key="edit-board-post-sheet"
      v-if="post"
      :visible="showEditBoardPostSheet"
      :post-body="post"
      @close="closeBoardPostSheet"
      @success="getPostDetails"
      is-editing
      :post-type="post.is_alert ? 'Message' : 'Notice'"
    /> -->
    <intract-create-edit-sheet
    persistent

      v-if="post"
      :title="'Edit ' + (postEditSheet.obj.is_alert ? 'Message' : 'Notice')"
      :visible="postEditSheet.visible"
      @close="
        postEditSheet.visible = false;
        postEditSheet.editId = null;
      "
      :fields="formFields"
      enable-media
      :endpoint="endpoints.boardPosts"
      :edit-id="postEditSheet.editId"
      :data-object="postEditSheet.obj"
      :edit-success-message="postEditSheet.editSuccessMessage"
      @objectCreated="getPostDetails"
      @updateObject="(obj) => (postEditSheet.obj = obj)"
    >
            <!-- schedule for later button slot -->
      <template v-slot:after-submit-button v-if="!postEditSheet.obj.scheduled || !(postEditSheet.obj.rooms && postEditSheet.obj.rooms.length > 1)">
        <v-dialog
            v-if="!postEditSheet.obj.scheduled"
            v-model="scheduleDialog.visible"
            width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                outlined
                block
                dark
                v-bind="attrs"
                v-on="on"
              >
                Schedule for Later
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Schedule for later
               <v-btn icon absolute top right @click="scheduleDialog.visible=false; scheduleDialog.datetime=null">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
              </v-card-title>

              <v-card-text>
                <v-text-field class="mt-4 mb-0" hide-details="auto" dense outlined type="datetime-local" label="Schedule Date & Time" v-model="scheduleDialog.datetime">
                </v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  :disabled="!scheduleDialog.datetime"
                  @click="postEditSheet.obj.scheduled = scheduleDialog.datetime; scheduleDialog.visible = false; scheduleDialog.datetime = null"
                >
                  Schedule 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 

      <!-- broadcast to other classrooms -->
        <v-dialog
            v-if="!(postEditSheet.obj.rooms && postEditSheet.obj.rooms.length > 1) && postEditSheet.obj.is_alert"
            v-model="broadcastDialog.visible"
            width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-2"
                color="blue"
                outlined
                block
                dark
                v-bind="attrs"
                v-on="on"
              >
                Broadcast to other Classrooms
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                <p class="mb-0" style="word-break: break-word">Broadcast to other classrooms</p>  
                <v-card-subtitle class="mb-1 py-0 px-1" style="word-break: break-word">with {{ room.sections }}</v-card-subtitle>
                <v-btn icon absolute top right @click="broadcastDialog.visible=false; broadcastDialog.classrooms=null">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-select
                  v-model="broadcastDialog.classrooms"
                  :items="room ? allRooms.filter(r => r.id != room.id) : allRooms"
                  color="primary"
                  dense 
                  outlined
                  multiple
                  class="mt-3"
                  label="Select Classrooms"
                  hide-details
                  item-value="id"
                  item-text="sections"
                  clearable
                  :rules="[rules.requiredArray]"
                ></v-select>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  :disabled="!broadcastDialog.classrooms"
                  @click="postEditSheet.obj.rooms = broadcastDialog.classrooms; broadcastDialog.visible = false; broadcastDialog.classrooms = null"
                >
                  Broadcast 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 

  
      </template>


      <!-- scheduled for information -->
      <template v-slot:custom-field--scheduled="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Scheduled for <strong class="orange--text">{{ moment(obj.scheduled).format('LLL') }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Will be published at the specified time.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.scheduled = null">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>

    <!-- broadcast information -->
       <template v-slot:custom-field--rooms="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Broadcast to
            </v-list-item-title> 
            <v-list-item-subtitle>
                <v-chip v-for="r in obj.rooms" :key="r" color="primary" outlined small class="mr-1">
                    {{ allRooms.find(room => room.id == r) ? allRooms.find(room => room.id == r).sections : "" }}  
                </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.rooms = obj.rooms.filter(r => r.id == room.id)">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <!-- test -->
      </template>  

  
    </intract-create-edit-sheet>
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this post?"
      description="This action cannot be undone"
      @successCallback="deletePost"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- scheduled post confirmation -->
    <confirmation-dialog
      :title="`Are you sure you want to ${ post.is_alert ? 'send' : 'post' } this ${ post.is_alert ? 'message' : 'notice' }`"
      description="This will notify the recepients."
      :visible="showPublishDialog"
      @successCallback="publishPost"
      @failureCallback="showPublishDialog = false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import api from "@api/index";
// import endpoints from "@api/repository";
import TextHelper from '@utils/text_helper';
import moment from "moment";
import FullScreenImageCarousel from "@components/dialogs/FullScreenImageCarousel";
// import FileOpener from 'cordova-plugin-file-opener2';
// import CreateBoardPostSheet from "@components/board/CreateBoardPostSheet";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import BoardPostReplies from "@components/board/BoardPostReplies";

// import { FileOpener } from "@ionic-native/file-opener";
import EventBus from "@utils/event_bus";
import Mixins from "@utils/mixins";
// import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";



export default {
  name: "ViewBoardPost",
  props: {
    room: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    FullScreenImageCarousel,
    // CreateBoardPostSheet,
    IntractCreateEditSheet,
    ConfirmationDialog,
    BoardPostReplies,
  },
  mixins: [Mixins.essentials, Mixins.downloadEssentials, Mixins.pullToRefresh, Mixins.handleAppBarTitle],

  data() {
    return {
      TextHelper,
      appBarTitle: '',
      featureName: null,
      moment: moment,
      fullScreenImageOverlay: false,
      fullScreenImageUrl: null,
      initialImage: null,
      showPublishDialog: false,
      post: null,
      showDeleteDialog: false,
      postEditSheet: {
        visible: false,
        editSuccessMessage: "Post edited successfully!",
        editId: null,
        obj: {
          is_alert: false,
          description: "",
          institution: null,
          recipients: [],
          allow_replies: false,
          scheduled: false,
          rooms: [],
        },
      },
      scheduleDialog: {
        visible: false,
        datetime: null,
      },
      broadcastDialog: {
        visible: false,
        classrooms: null,
      },
      rules: {
        // required: (v) => !!v || "Required",
        requiredArray: (v) => !!(v && v.length) || "Required",
      },
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "currentInstitution",
      "accessToken",
      "allRooms",
    ]),
    documentsList() {
      return this.post.board_documents;
    },
    canManage() {
      return this.post.user.id == this.currentUser.id || this.currentUser.is_admin;
    },
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },

    formFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        allow_replies: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Allow Replies",
          helper: "Will allow students to add replies to this notice. Replies will also be visible to other students.",
          required: false,
          md: 12,
        },
        scheduled: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: this.postEditSheet.obj.scheduled ? false: true,
        },
        rooms: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: (this.postEditSheet.obj.rooms && this.postEditSheet.obj.rooms.length > 1) ? false: true,          
        },
        board_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        board_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setAppBarOptions",
      "showSnackbar",
      "resetAppBarOptions",
    ]),

    async showFullScreenCarousel(image) {
      this.initialImage = image;
      this.fullScreenImageOverlay = true;
    },

    async publishPost() {
      this.showPublishDialog = false;

      var url = this.endpoints.boardPosts + this.post.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { is_draft: false, scheduled: null }
      );
      if (response) {
        this.post.is_draft = false;
        this.showSnackbar({
          title: `This ${ this.post.is_alert? 'message' : 'notice' } has been ${ this.post.is_alert? 'sent.' : 'posted.' }`,
          text: "The recepients will be notified.",
          type: "success",
        });
        EventBus.$emit("view_board_post__post_updated", this.post);
      }
    },


    async closeFullScreenCarousel() {
      this.initialImage = null;
      this.fullScreenImageOverlay = false;
    },

    async getPostDetails() {
      var url = this.endpoints.boardPosts + this.$route.params.postId + "/";
      var response = await this.api.call(this.essentials, url);
      this.post = response;
      this.checkIfDocumentsDownloaded();
      // tell previous page that post has been edited
      this.featureName = this.post.is_alert ? "Messages" : "Notice";
      EventBus.$emit("view_board_post__post_updated", this.post);
      this.postEditSheet.obj.is_alert = this.post.is_alert;
      if(this.post.recipients.length) this.postEditSheet.obj.recipients = this.post.recipients;
      this.appBarTitle = this.post.is_alert ? 'View Message' : 'View Notice'; // set app bar title

    },

    async deletePost() {
      this.showDeleteDialog = false;
      var url = this.endpoints.boardPosts + this.$route.params.postId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Post successfully deleted",
          type: "success",
        });
        EventBus.$emit(
          "view_board_post__post_deleted",
          this.$route.params.postId
        );
        this.$router.back();
      }
    },

    async onRefresh() {
      this.getPostDetails();
    },

    // async downloadDocument(documentFile) {
    //   documentFile.featureName = this.post.is_alert ? "Alert" : "Notice";

    //   this.api.download(
    //     this,
    //     this.essentials,
    //     documentFile.document,
    //     documentFile,
    //     "downloads/"
    //   );
    // },

    // async openFile(document) {
    //   console.log("DOCUMENT", document);
    //   await this.checkIfDocumentsDownloaded();
    //   await FileOpener.open(document.document_uri, document.document_type);
    // },

    // async checkIfDocumentsDownloaded() {
    //   var { Storage } = await import("@capacitor/storage");
    //   var downloadedFiles = (await Storage.get({ key: "downloadedFiles" }))
    //     .value;
    //   downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
    //   // if file already exists in downloads, assign path to that board document file
    //   // which will then be used for opening the file
    //   var self = this;
    //   this.post.board_documents.map((bd) => {
    //     // find if document already downloaded
    //     var downloadedFile = downloadedFiles.find(
    //       (df) =>
    //         df.featureDocumentId == bd.id &&
    //         df.fileName == bd.file_name &&
    //         (df.feature == "Notice" || df.feature == "Alerts")
    //     );
    //     if (downloadedFile) {
    //       bd.document_path = downloadedFile.path;
    //       bd.document_uri = downloadedFile.uri;
    //       bd.document_type = downloadedFile.type;
    //       self.$set(bd, "progress", 100);
    //     }
    //   });

    //   // Object.keys(downloadedFiles.downloads).map((fileName) => {
    //   //   this.post.board_documents.map((bd) => {
    //   //     if (bd.file_name == fileName)
    //   //       bd.document_path = downloadedFiles.downloads[fileName].path;
    //   //     bd.document_type = downloadedFiles.downloads[fileName].type;
    //   //     self.$set(bd, "progress", 100);
    //   //   });
    //   // });
    // },

    async closeBoardPostSheet() {
      // close dialog
      this.showEditBoardPostSheet = false;
    },

    async handleApiError(err) {
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({
          title: "Unable to process request",
          type: "error",
        });
      }
    },

    async setAppBar() {
      this.setAppBarOptions({
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.postEditSheet.editId = Number(this.$route.params.postId);
              this.postEditSheet.visible = true;
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetAppBarOptions();
    next();
  },
  async created() {
    await this.getPostDetails();
    if (!this.currentUser.is_student) {
      this.postEditSheet.obj.institution = this.currentInstitution.id;
      this.postEditSheet.obj.user = this.currentUser.id;
      
    }
    if(this.canManage) this.setAppBar();
  },
};
</script>
<style scoped>
.hide-button {
  z-index: 50;
  display: fixed;
  top: 5px;
  left: 5px;
}
</style>